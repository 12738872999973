import {
    Card,
    CardHeader,
    Title2,
    Title3,
    Body1,
    Text
} from '@fluentui/react-components';
import {
    MailRegular,
    CallRegular
} from '@fluentui/react-icons';

import { useOncologieStyles } from './styles';

export default function Teams() {
    const classes = useOncologieStyles();

    return (
        <>
            <Title2 as='h2' block>Les équipes</Title2>
            <div className={classes.twoColumnsContainer}>
                <div>
                    <Title3 as='h3'>CHUV</Title3>
                    <Body1 block as='p'><Text weight='bold'>Le Centre hospitalier universitaire vaudois (CHUV) </Text>comprend dix-sept départements cliniques, médico-techniques, académiques et administratifs, ainsi qu'un EMS psychogériatrique à Gimel. Grâce à sa collaboration avec la Faculté de biologie et médecine de l'Université de Lausanne, le CHUV joue un rôle de pointe d'envergure européenne dans les domaines des soins médicaux, de la recherche médicale et de la formation.</Body1>
                    <Body1 block as='p'><Text weight='bold'>Son département d'oncologie</Text>, réunit la clinique et la recherche sur le cancer dans un esprit d'interdisciplinarité et d'innovation. Il comprend les services : d'oncologie médicale, de radio-oncologie, d'hématologie (avec l'activité de transplantation de la moelle osseuse), d'immuno-oncologie, le centre des thérapies expérimentales ainsi que l'unité d'oncologie personnalisée analytique. Il intègre également le Ludwig Institute for Cancer Research Lausanne pour la recherche sur le cancer, en particulier dans le domaine de l'immunothérapie.</Body1>
                </div>
                <div>
                    <Title3 as='h3'>HUG</Title3>
                    <Body1 block as='p'>Les <Text weight='bold'>Hôpitaux universitaires de Genève (HUG)</Text> poursuivent une tradition d'excellence en médecine et en sciences et sont reconnus au plan national et international dans plusieurs disciplines de pointe, dont l'oncologie.
                        <br />
                        Au sein du <Text weight='bold'>département d'oncologie</Text>, les expertises des services d'oncologie médicale, de radio-oncologie et d'hématologie sont réunies pour offrir au patient atteint de cancer une prise en charge de haut niveau. L'accès aux meilleurs traitements anticancéreux est associé à un parcours de soin complet visant à gérer les conséquences de la maladie et des traitements. La prise en charge multidisciplinaire de chaque patient est assurée grâce aux <Text weight='bold'>tumor boards</Text> du centre des cancers, qui réunissent les médecins experts des spécialités diagnostiques, médicales et chirurgicales.
                        <br />
                        L'accès à des traitements innovants encore indisponibles sur le marché est rendue possible dans le cadre d'essais cliniques grâce à l'<Text weight='bold'>unité de recherche clinique en onco-hématologie Fondation Dr Henri Dubois-Ferrière Dinu Lipatti</Text> (DFDL), qui réalise un travail d'un haut niveau d'expertise en assurant un environnement de qualité et de sécurité des soins.
                    </Body1>
                </div>
            </div>
            <div className={classes.twoColumnsContainer}>
                <Card className={classes.card}>
                    <CardHeader
                        image={
                            <img
                                src='/chuv-logo.png'
                                alt='CHUV logo'
                            />
                        }
                        header={<Text weight='semibold'>Centre Hospitalier Universitaire Vaudois</Text>}
                    />
                    <ul>
                        <li>Rue du Bugnon 46</li>
                        <li>1011 Lausanne</li>
                        <li>Suisse</li>
                        <li><CallRegular />+41 79 556 28 45</li>
                        <li><MailRegular />fabienne.gaillard@chuv.ch ; do.cte.amr@chuv.ch</li>
                    </ul>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        image={
                            <img
                                src='/hug-logo.png'
                                alt='HUG logo'
                            />
                        }
                        header={<Text weight='semibold'>Hôpitaux Universitaires de Genève - Unité de recherche clinique DFDL</Text>}
                    />
                    <ul>
                        <li>Rue Gabrielle-Perret-Gentil 4</li>
                        <li>1205 Genève</li>
                        <li>Suisse</li>
                        <li><CallRegular />+41 22 372 29 01</li>
                        <li><MailRegular />Julie.Chassot@hug.ch</li>
                    </ul>
                </Card>
            </div>
        </>
    )
}
